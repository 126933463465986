import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium } from 'components/typography';
import {
  warehousingCompanies,
  couriersCompanies,
  otherCompanies,
} from 'components/pages/Partners/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';

export const DetailContent = styled.div`
  ${HeaderMedium} {
    margin-top: 40px;
    margin-bottom: 80px;
  }
`;

export const PageContainer = styled.div`
  margin-bottom:0;
  width:100%;

  .column-whole {
    position:relative;
  }

  .column-half {
    width: 48%;
    display: inline-block;
    float: left;
    padding-right:4%;
  }

  .column-half.last {
    width: 48%;
    padding-right:0;
  }

  .column-third {
    width:31%;
    display:inline-block;
    margin-right:3%;
    float:left;
  }

  .column-third.last {
    margin-right:0;
  }

`;

export const MainSection = styled.div`
  position:relative;

  #hero-section {
    padding:0;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  #hero-section .column-half {
    width: 50%;
    padding-right: 0;
    position: relative;
    padding-top: 45px;
    padding-bottom: 80px;
    padding-left: 8%;
    display: inline-block;
    float: left;
  }

  #hero-section .column-whole {
    padding: 150px 0px 150px 310px;
    margin-left: 10%;
  }

  #hero-section .content-container {
    position: relative;
    z-index: 2;
  }

  #hero-section .column-half p{
    padding-right:60px;
  }

  #hero-section .column-half.last {
    width: 50%;
    padding-right: 0;
    position: relative;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #hero-section .column-half img{
      width: 100%;
  }

  .hero-bg {
    position: absolute;
    top: 0;
    left:0;
    object-fit: cover;
    right:0;
    height: 100%;
    width: 100%;
    bottom: 0;
    float:left;
  }

  #hero-section .hero-bg-tablet {
    display:none;
  }

  #hero-section .hero-bg-mobile {
    display:none;
  }

  @media only screen and (max-width:1295px) {
    .hero-bg {
      display:none;
    }

    #hero-section .hero-bg-tablet {
      display:block;
      object-position: 30% 0;
    }
  }

  @media only screen and (max-width:1025px) { 
    #hero-section .column-whole {
      padding: 150px 0px 150px 240px;
      margin-left: 10%;
    }
  }

  @media only screen and (max-width:981px) {
    #hero-section .column-half {
      width: 100%;
      padding:0;
    }

    #hero-section .column-half img{
      width: 100%;
    }

    #hero-section .column-half p{
      padding-right:0;
    }

    #hero-section .column-half.last {
      width:100%;
      padding-top:25px;
    }

    #hero-section {
      padding:40px 25px;
    }

    #hero-section h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width:875px) {
    #hero-section .hero-bg-mobile {
      display:block;
      object-fit:contain;
    }
    #hero-section .hero-bg-tablet {
      display:none;
    }

    #hero-section .column-whole {
      padding: 80px 0px 100px 0px;
      margin-left: 0;
    }
  }
`;

export const ShippingSectionURL = styled.div`
  #tab-navigation {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  #tab-navigation h3:after {
    content: '';
    position: absolute;
    width: 60px;
    height: 4px;
    background: #05ad89;
    bottom: 0;
    left: 0;
    right: 0;
    top: 40px;
    margin: auto;
  }

  #tab-navigation h3 {
    text-align:center;
    position:relative;
    color:#4b4b4b;
    font-size: 17px;
    font-weight: 400;
  }

  @media only screen and (max-width:981px) {
    
    #tab-navigation {
      padding: 40px 25px 20px 25px;
    }

    #tab-navigation div {
      width: 100%;
      padding-bottom: 45px;
    }

    #tab-navigation div:nth-child(4) {
      padding-bottom:0;
    }

  }
  
`;

export const ShippingSectionProducts = styled.div`
  padding-top:25px;

  button {
    margin-left:auto;
    margin-right:auto;
  }

  #amazon-holiday-deadlines {
    padding-bottom:80px;
  }

  #amazon-holiday-deadlines .column-half {
    width: 70%;
    padding-right: 2%;
    position: relative;
    padding-top: 50px;
    padding-bottom: 0px;
    padding-left: 8%;
    display: inline-block;
    float: left;
  }

  #amazon-holiday-deadlines  .column-half.last {
    width: 30%;
    padding-right: 0;
    position: relative;
    display: inline-block;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #amazon-holiday-deadlines h2 {
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom:25px;
  }

  #ebay-holiday-deadlines {
    padding-bottom:70px;
  }

  #ebay-holiday-deadlines .column-half {
    width: 30%;
    padding-right: 2%;
    position: relative;
    padding-bottom: 0px;
    padding-left: 8%;
    display: inline-block;
    float: left;
    margin-top:-50px
  }

  #ebay-holiday-deadlines  .column-half.last {
    width: 70%;
    padding-right: 0;
    position: relative;
    display: inline-block;
    padding-top: 50px;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #ebay-holiday-deadlines h2 {
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom:25px;
  }

  #shipping-guide h2 {
    text-align:center;
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom: 35px;
  }

  #shipping-guide {
    padding-top: 30px;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
  }

  #shipping-guide p{
    text-align: center;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
    margin-bottom:25px;
  }

  #shipping-guide .column-third {
    width: 31%;
    display: inline-block;
    position: relative;
    margin-right: 3%;
    padding: 0;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    float: left;
    max-height: 240px;
    min-height: 240px;
    background: transparent;
    border: 0;
  }

  .onhover {
    margin-top:-10px;
  }

  #shipping-guide .column-third.last {
    margin-right:0;
  }

  #shipping-guide .column-third .box-icon {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    max-height: 75px;
    position:relative;
    z-index:2;
  }

  #shipping-guide .column-third img.box-bg { 
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    max-height: 100%;
    margin-bottom: 0;
  }

  #shipping-guide .column-third p.hover {
    z-index: 2;
    margin-top: 65px;
    color: #000;
    margin-bottom: 5px; 
  }

  #shipping-guide .column-third.last p.hover:first-of-type {
    margin-top: 55px;
  }
  #shipping-guide .column-third:hover {
    cursor:pointer;
  }

  #shipping-guide .column-third:hover .box-icon{
    display:none;
    animation: fadeIn ease 1s;
  }

  #shipping-guide .column-third:hover p.hover{
    display:none;
    animation: fadeIn ease 1s;
  }

  #shipping-guide .column-third:hover p.onhover{
    display:block;
    animation: fadeIn ease 1s;
    position: relative;
    z-index: 2;
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 45px;
    color: #000;
  }
  #shipping-guide .column-third:hover img.box-bg  {
      opacity: 0.5;
  }

  #shipping-guide .column-third .onhover{
    display:none;
    animation: fadeIn ease 1s;
  }

  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  #shipping-deadlines .column-half {
    width:25%;
  }

  #shipping-deadlines .column-half.last {
    width:75%;
  }

  #shipping-deadlines .deadline-table {
    width:100%;
    color:#4b4b4b;
    font-size:15px;
  }

  #shipping-deadlines .column-half img {
    max-width:100px;
    margin-left:auto;
    margin-right:auto;
  }

  #shipping-deadlines .column-half img.fedex-icon {
    max-width:100%;
    margin-top:65px;
  }

  #shipping-deadlines .column-half img.ups-icon {
    margin-top:20px;
  }
  
  #shipping-deadlines .row-small {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom:40px;
    padding-top:10px;
  }

  #shipping-deadlines .row-small strong {
    font-size:18px;
  }

  #shipping-deadlines .deadline-table.last {
    text-align:center;
    border-top:0;
  }


  #shipping-deadlines p{
    text-align: center;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
    margin-bottom:25px;
  }

  #shipping-deadlines h2 {
    text-align:center;
    font-size: 30px;
    color: #00AF85;
    font-weight: 600;
    margin-bottom: 35px;
  }

  @media only screen and (max-width:981px) {
    
    #amazon-holiday-deadlines {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #amazon-holiday-deadlines h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #amazon-holiday-deadlines .column-half {
      width: 100%;
      padding: 0;
    }

    #amazon-holiday-deadlines .column-half.last {
      width: 100%;
      padding: 0;
    }

    #shipping-guide {
      padding-top:0px;
    }

    #shipping-guide h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #shipping-guide .column-third {
      width:100%;
      display:inline-block;
      margin-right:0;
      margin-top: 20px;
    }

    #shipping-guide {
      padding-right:25px;
      padding-left:25px;
    }

    #shipping-guide h2 {
        margin-bottom: 20px;;
    }

    #shipping-guide p {
        margin-bottom: 15px;
    }

    #shipping-deadlines h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #shipping-deadlines .column-half {
      width: 100%;
      padding: 0;
    }

    #shipping-deadlines {
      padding-left:25px;
      padding-right:25px;

    }

    #shipping-deadlines .column-half img {
      margin-bottom: 25px;
    }

    #shipping-deadlines .column-half img.fedex-icon {
      margin-top: 0;
      max-width: 150px;
    }

    #shipping-deadlines .column-half img.ups-icon {
      margin-top: 0;
    }

    #shipping-deadlines .column-half.last {
      width: 100%;
      padding: 0;
    }

    #ebay-holiday-deadlines {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #ebay-holiday-deadlines h2 {
      font-size:28px;
      margin-bottom:20px;
    }

    #ebay-holiday-deadlines .column-half {
      width: 100%;
      padding: 0;
      margin-top:0;
    }

    #ebay-holiday-deadlines .column-half.last {
      width: 100%;
      padding: 0;    
      padding-top: 35px;
    }

    #amazon-holiday-deadlines {
      padding-left:25px;
      padding-right:25px;
      padding-top:25px;
    }

    #amazon-holiday-deadlines h2 {
      font-size:28px;
      margin-bottom:20px;
    }

  }

`;