import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import ArrowButton from 'components/Button/ArrowButton';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium } from 'components/typography';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';
import HeroBg from 'images/holiday-shipping/hero-bg.png';
import HeroBgTablet from 'images/holiday-shipping/hero-tablet.png';
import HeroBgMobile from 'images/holiday-shipping/hero-mobile.png';
import RowBg from 'images/holiday-shipping/hero-tablet.png';
import AmazonHoliday from 'images/holiday-shipping/amazon-holiday-deadlines.png';
import EbayHoliday from 'images/holiday-shipping/ebay-holiday-deadlines.png';
import BoxIcon1 from 'images/holiday-shipping/calendar-icon.png';
import BoxIcon2 from 'images/holiday-shipping/customer-icon.png';
import BoxIcon3 from 'images/holiday-shipping/priority-country-icon.png';
import BoxIcon4 from 'images/holiday-shipping/truck-icon.png';
import BoxIcon5 from 'images/holiday-shipping/gift-icon.png';
import BoxIcon6 from 'images/holiday-shipping/open-box-icon.png';
import BoxBg1 from 'images/holiday-shipping/plan-ahead.png';
import BoxBg2 from 'images/holiday-shipping/shipping-option.png';
import BoxBg3 from 'images/holiday-shipping/priority-country.png';
import BoxBg4 from 'images/holiday-shipping/export-solution.png';
import BoxBg5 from 'images/holiday-shipping/holiday-shipping.png';
import BoxBg6 from 'images/holiday-shipping/review-return-policy.png';
import UspsIcon from 'images/holiday-shipping/usps-logo.png';
import FedexIcon from 'images/holiday-shipping/fedex-logo.png';
import UpsIcon from 'images/holiday-shipping/ups-logo.png';

import { DetailContent, PageContainer, MainSection, ShippingSectionURL, ShippingSectionProducts }  from 'components/pages/Resources/HolidayShippingStyled';

const ContentSmall = styled.p`
    position: relative;
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
    margin-bottom:25px;
`;

const ContentSmallSpan = styled.span`
    position: relative;
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 400;
    line-height:1.5;
`;

const Clear  = styled.div`
  clear:both;
  padding:0 !important;
`;

const Row  = styled.div`
  max-width:1300px;
  margin-left:auto;
  margin-right:auto;
`;

const TabNavigation = styled.div`
  width:33.3%;
  display:inline-block;

`;

const HeroHeader = styled.h1`
  font-size: 35px;
  margin-bottom: 20px;
`;

const HeroText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #7f7f7f;
  line-height:1.8;
`;

const HolidayShipping = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        <title>Holiday Shipping Guide | Shipkoo</title>
        
        <link rel="alternate" href={intl.formatMessage({ id: "holiday-shipping-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "holiday-shipping-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "holiday-shipping-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "holiday-shipping-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Holiday season is the busiest time for online sellers, and Shipkoo got you covered with our holiday shipping guide for you to stay at the top of your online business!" />
        <meta property="og:title" content="Holiday Shipping Guide | Shipkoo" />
        <meta property="og:description" content="Holiday season is the busiest time for online sellers, and Shipkoo got you covered with our holiday shipping guide for you to stay at the top of your online business!" />
        <meta name="twitter:title" content="Holiday Shipping Guide | Shipkoo" />
        <meta name="twitter:description" content="Holiday season is the busiest time for online sellers, and Shipkoo got you covered with our holiday shipping guide for you to stay at the top of your online business!" />
      </Helmet>
      <PageContainer>
        <MainSection>
          <div id="hero-section">
            <img className="hero-bg" src={HeroBg} alt="shipkoo holiday shipping guide"/>
            <img className="hero-bg hero-bg-tablet" src={HeroBgTablet} alt="shipkoo holiday shipping guide"/>
            <img className="hero-bg hero-bg-mobile" src={HeroBgMobile} alt="shipkoo holiday shipping guide"/> 
            <Row>
                <div className="column-whole">
                  <div className="content-container">
                    <HeroHeader>{intl.formatMessage({ id: "hs-header" })}<br/>{intl.formatMessage({ id: "hs-subheader" })}</HeroHeader>
                    <HeroText>{intl.formatMessage({ id: "hs-description" })}</HeroText>
                  </div>
                  <img className="hero-bg" src={RowBg} alt="shipkoo holiday shipping guide" />  
                </div>
                <Clear></Clear>
            </Row>
          </div>  
        </MainSection>        
        <ShippingSectionURL>
          <Row>
            <div id="tab-navigation">
              <TabNavigation>
                <a href="#shipping-guide">
                  <h3>{intl.formatMessage({ id: "hs-shipping-guide-tab" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#shipping-deadlines">
                  <h3>{intl.formatMessage({ id: "hs-shipping-deadline-tab" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#amazon-holiday-deadlines">
                  <h3>{intl.formatMessage({ id: "hs-amazon-holiday-tab" })}</h3>
                </a>
              </TabNavigation>
              <Clear></Clear>
            </div> 
          </Row>
        </ShippingSectionURL>
        <ShippingSectionProducts>
          <div id="shipping-guide">
            <Row>
              <h2>
                {intl.formatMessage({ id: "hs-holiday-shipping-title" })}
              </h2>
              <ContentSmall>
                {intl.formatMessage({ id: "hs-holiday-shipping-desc1" })}
              </ContentSmall>  
              <ContentSmall>
                {intl.formatMessage({ id: "hs-holiday-shipping-desc2" })}
              </ContentSmall>
              <div className="column-third">
                <p className="hover">
                  <strong>{intl.formatMessage({ id: "hs-holiday-shipping-col1-title" })}</strong>
                </p>
                <img className="box-icon" src={BoxIcon1} alt="plan ahead of season icon" /> 
                <p className="onhover">
                  {intl.formatMessage({ id: "hs-holiday-shipping-col1-desc" })}
                </p>
                <img className="box-bg" src={BoxBg1} alt="shipkoo holiday shipping guide plan ahead of the season" />
              </div>
              <div className="column-third">
                <p className="hover">
                  <strong>{intl.formatMessage({ id: "hs-holiday-shipping-col2-title" })}</strong>
                </p>
                <img className="box-icon" src={BoxIcon2} alt="provide shipping options icon" /> 
                <p className="onhover">
                  {intl.formatMessage({ id: "hs-holiday-shipping-col2-desc" })}
                </p>
                <img className="box-bg" src={BoxBg2} alt="shipkoo holiday shipping guide provide shipping options" />
              </div>
              <div className="column-third last">
                <p className="hover">
                  <strong>{intl.formatMessage({ id: "hs-holiday-shipping-col3-title" })}</strong>
                </p>
                <img className="box-icon" src={BoxIcon3} alt="identify priority countries and shipping restrictions icon" /> 
                <p className="onhover">
                  {intl.formatMessage({ id: "hs-holiday-shipping-col3-desc" })}
                </p>
                <img className="box-bg" src={BoxBg3} alt="shipkoo shipping this holiday season identify priority countires" />
              </div>
              <div className="column-third">
                <p className="hover">
                  <strong>{intl.formatMessage({ id: "hs-holiday-shipping-col4-title" })}</strong>
                </p>
                <img className="box-icon" src={BoxIcon4} alt="identify shipping solutions icon" /> 
                <p className="onhover">
                  {intl.formatMessage({ id: "hs-holiday-shipping-col4-desc" })}
                </p>
                <img className="box-bg" src={BoxBg4} alt="shipkoo shipping this holiday season identify shipping solutions" />
              </div>
              <div className="column-third">
                <p className="hover">
                  <strong>{intl.formatMessage({ id: "hs-holiday-shipping-col5-title" })}</strong>
                </p>
                <img className="box-icon" src={BoxIcon5} alt="holiday shipping offers icon" />
                <p className="onhover">
                  {intl.formatMessage({ id: "hs-holiday-shipping-col5-desc" })}
                </p>
                <img className="box-bg" src={BoxBg5} alt="shipkoo shipping this holiday season holiday shipping offers" />
              </div>
              <div className="column-third last">
                <p className="hover">
                  <strong>{intl.formatMessage({ id: "hs-holiday-shipping-col6-title" })}</strong>
                </p>
                <img className="box-icon" src={BoxIcon6} alt="review return policy icon" />
                <p className="onhover">
                  {intl.formatMessage({ id: "hs-holiday-shipping-col6-desc" })}
                </p>
                <img className="box-bg" src={BoxBg6} alt="shipkoo shipping holidays review return policy" />
              </div>
              <Clear></Clear>
            </Row>
          </div>
        </ShippingSectionProducts>
        <ShippingSectionProducts>
          <div id="shipping-deadlines">
            <Row>
              <h2>
                {intl.formatMessage({ id: "hs-holiday-deadlines-title" })}
              </h2>
              <ContentSmall>
                {intl.formatMessage({ id: "hs-holiday-deadlines-desc" })}
              </ContentSmall>
              <div className="row-small">
                <ContentSmall>
                  <strong>{intl.formatMessage({ id: "hs-usps-title" })}</strong>
                </ContentSmall>
                <div className="column-half">
                  <img className="usps-icon" src={UspsIcon} alt="usps logo" />
                </div>
                <div className="column-half last">
                  <table className="deadline-table" border="1" cellPadding="5">
                    <tbody>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-usps-list1-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-usps-list1-date" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-usps-list2-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-usps-list2-date" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-usps-list3-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-usps-list3-date" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-usps-list4-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-usps-list4-date" })}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="deadline-table last" border="1" cellPadding="5">
                    <tbody>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-usps-bottom-desc" })}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Clear></Clear>
              </div>
              <div className="row-small">
                <ContentSmall>
                  <strong>{intl.formatMessage({ id: "hs-fedex-title" })}</strong>
                </ContentSmall>
                <div className="column-half">
                  <img className="fedex-icon" src={FedexIcon} alt="fedex logo" />
                </div>
                <div className="column-half last">
                  <table className="deadline-table" border="1" cellPadding="5">
                    <tbody>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-fedex-list1-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-fedex-list1-date" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-fedex-list2-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-fedex-list2-date" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-fedex-list3-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-fedex-list3-date" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-fedex-list4-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-fedex-list4-date" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-fedex-list5-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-fedex-list5-date" })}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="deadline-table last" border="1" cellPadding="5">
                    <tbody>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-fedex-bottom-desc" })}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Clear></Clear>
              </div>
              <div className="row-small">
                <ContentSmall>
                  <strong>{intl.formatMessage({ id: "hs-ups-title" })}</strong>
                </ContentSmall>
                <div className="column-half">
                  <img className="ups-icon" src={UpsIcon} alt="ups logo" />
                </div>
                <div className="column-half last">
                  <table className="deadline-table" border="1" cellPadding="5">
                    <tbody>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-ups-list1-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-ups-list1-desc" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-ups-list2-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-ups-list2-desc" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-ups-list3-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-ups-list3-desc" })}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-ups-list4-title" })}</td>
                        <td>{intl.formatMessage({ id: "hs-ups-list4-desc" })}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="deadline-table last" border="1" cellPadding="5">
                    <tbody>
                      <tr>
                        <td>{intl.formatMessage({ id: "hs-ups-bottom-desc" })}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Clear></Clear>
              </div>
            </Row>
          </div>
        </ShippingSectionProducts>      
        <ShippingSectionProducts>
          <Link to="/contact">
            <ArrowButton >{intl.formatMessage({ id: "drop-a-line-button" })}</ArrowButton>
          </Link>
          <div id="amazon-holiday-deadlines">
            <Row>
              <div className="column-half">
                <h2>
                  {intl.formatMessage({ id: "hs-amazon-holiday-title" })}
                </h2>
                <ContentSmall>
                  {intl.formatMessage({ id: "hs-amazon-holiday-desc1" })} 
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "hs-amazon-holiday-desc2" })}
                </ContentSmall>
                <ContentSmall>
                  {intl.formatMessage({ id: "hs-amazon-holiday-desc3" })} 
                </ContentSmall>
              </div>
              <div className="column-half last">
                <img src={AmazonHoliday} alt="shipkoo amazon deadline for holiday shipping" />
              </div>
              <Clear></Clear>
            </Row> 
          </div>
        </ShippingSectionProducts>
      </PageContainer>
    </Layout>
  );
};

export default HolidayShipping;
